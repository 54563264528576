// import axios from "axios";
import axios from "axios";
import { BMP_BE } from "../../src/configurations/ServerCreds";

class UserDetailsApi {
  /////////////////////////////////{Get User Method}////////////////////////////////

  getUser(requestObj) {
    return axios.get(BMP_BE + "user/v2/getUser" + "/" + requestObj);
  }

  createUser(requestObj) {
    return axios.post(BMP_BE + "user/v1/legacy/createUser", requestObj);
  }
  createOrUpdateCustomRole(requestObj) {
    return axios.post(BMP_BE + "permission/v2/createOrUpdateCustomRole", requestObj);
  }
  getModuleAccess(roleId, clientId) {
    return axios.get(BMP_BE + `permission/v2/getModuleAccess`);
  }
  getCustomIdentifier(emailId) {
    return axios.get(BMP_BE + `user/v2/getCustomIdentifier`);
  }
}

export default new UserDetailsApi();
