import axios from "axios";
import { BMP_BE } from "../configurations/ServerCreds";

class AuthenticateModuleAPI {
    //changing below api from "oauth/token" to ""
    authenticateUser(data, config) {
        return axios.post(BMP_BE + "auth/public/login", data, config);
    }
    refreshToken(data, config) {
        return axios.post(BMP_BE + "auth/public/getToken", data, config);
    }
    loginAction() {
        return axios.get(`${BMP_BE}loginHelper/v1/loginAction`)
    }
    // verifyCaptcha(captchaObj){
    //    return axios.post(`${BMP_BE}captcha/v1/verify`,captchaObj)
    // }
    verifyCaptchaPwd(logincaptchaObj) {
        return axios.post(`${BMP_BE}captcha/v1/verify`, logincaptchaObj)
    }
    logoutAction( config) {
        return axios.get(BMP_BE + "loginHelper/v1/logoutAction", config);
    }
    getOtp(otpData, purpose) {
        return axios.post(BMP_BE + `otp/v1/generate?purpose=${purpose}`, otpData)
    }
    resendOtp(data, purpose) {
        return axios.post(BMP_BE + `otp/v1/regenerate/?purpose=${purpose}`, data)
    }
    verifyOtp(email, otp, purpose) {
        return axios.get(BMP_BE + `otp/v1/validate/${email}?otp=${otp}&purpose=${purpose}`)
    }
    resetPassword(data, purpose) {
        return axios.post(BMP_BE + `password/v2/update?purpose=${purpose}`, data)
    }
    trialUserOtp(emailId, purpose, firstName) {
        return axios.get(BMP_BE + `/otp/v1/generate/${emailId}?purpose=${purpose}&firstName=${firstName}`)
    }
    createTrialUser(userObj) {
        return axios.post(BMP_BE + `user/v2/createTrialUser`, userObj)
    }

    checkAccountname(accountName) {
        return axios.get(`${BMP_BE}account/getAccountName/${accountName}`)
    }

    yorbtUserVerify(data) {
        return axios.post(`${BMP_BE}yorbit/user/v1/verify`, data)
    }

    extendyorbitUser(data) {
        return axios.post(`${BMP_BE}yorbit/user/v1/extend`, data)
    }


}
export default new AuthenticateModuleAPI;