export const DATATABLE_DEFAULT_OPTIONS = {
    selectableRows: 'none',
    tableBodyHeight: '100%',
    filterType: 'textField',
    responsive: 'scrollFullHeight',
    print: false,
    search: false,
    download: false,
    viewColumns: false,
    filter: false,
    enableNestedDataAccess: '.',
}

export const CHANNELS_LIST = ['web', 'whatsapp', 'teams', 'slack', 'android', 'facebook', 'hangouts'];

export const USER_ROLE_PERMISSION = [
    {
        "moduleId": "3",
        "moduleName": "Access Management",
        "readPermission": false,
        "writePermission": false
    },
    {
        "moduleId": "6",
        "moduleName": "API Integration",
        "readPermission": false,
        "writePermission": false
    },
    {
        "moduleId": "1",
        "moduleName": "Dashboard",
        "readPermission": true,
        "writePermission": false
    },
    {
        "moduleId": "2",
        "moduleName": "Homepage",
        "readPermission": true,
        "writePermission": false
    },
    {
        "moduleId": "4",
        "moduleName": "Intelligence",
        "readPermission": true,
        "writePermission": false
    },
    {
        "moduleId": "5",
        "moduleName": "Workflows",
        "readPermission": true,
        "writePermission": false
    },
    {
        "moduleId": "7",
        "moduleName": "Channel Integration",
        "readPermission": false,
        "writePermission": false
    },
    {
        "moduleId": "8",
        "moduleName": "Reporting",
        "readPermission": true,
        "writePermission": false
    },
    {
        "moduleId": "9",
        "moduleName": "Settings",
        "readPermission": false,
        "writePermission": false
    },
    {
        "moduleId": "11",
        "moduleName": "Custom Space",
        "readPermission": false,
        "writePermission": false
    }
]

export const ExcludedDomains = ["gmail.com", "yahoo.com", "yahoomail.com", "yahoo.co.in", "aol.com", "outlook.com", "hotmail.com", "icloud.com", "protonmail.com",
    "pm.com", "zohomail.in", "yandex.com", "titan.email", "gmx.com", "hubspot.com", "mail.com", "tutanota.com"];